import { LoaderPinwheel } from 'lucide-react'

export function Loading() {
  return (
    <div role="loading">
      <LoaderPinwheel className="animate-spin" />
      <span className="sr-only">Loading...</span>
    </div>
  )
}
