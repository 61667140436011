'use client'
import * as React from 'react'

import { LogIn } from 'lucide-react'

import { Loading } from '@/components/Loading'
import { Button } from '@/components/ui/button'

import { cn } from '@/lib/utils'

import { signInMicrosoft } from './sign-in-microsoft'

export function UserAuthForm({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const [isLoading, setLoading] = React.useState(false)
  const login = async () => {
    setLoading(true)
    await signInMicrosoft()
  }
  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <div className="grid gap-2">
        <Button
          className="w-full"
          onClick={login}
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? (
            <>
              <Loading />
              Signing
            </>
          ) : (
            <>
              <LogIn className="mr-2 h-4 w-4" />
              Sign
            </>
          )}{' '}
          in with SSO
        </Button>
      </div>
    </div>
  )
}
