import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function highlightText(text: string, searchTerm: string): JSX.Element {
  if (!searchTerm) return <>{text} </>

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="bg-primary dark:text-background">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  )
}
